@import "./vars";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

* {
  font-family: 'Montserrat', sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

h1, h2, h3, h4, h5, ul, p, a {
  margin: 0;
  padding: 0;
}

h2, h3 {
  font-family: $font2;
  font-size: $large;
}

p {
  line-height: 1.8;
  font-size: $regular;
}

a {
  color: $black;
}

li {
  list-style-type: none;
}

label {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

input, textarea, select {
  background: none;
  outline: none;
  border: none;
  font-size: $small;
  padding: 10px;
  width: 100%;
  line-height: 1.7;
}

fieldset {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border: none;
  min-inline-size: min-content;
}

button {
  background: none;
  outline: unset;
  border: unset;

  &:hover {
    cursor: pointer;
  }
}

.btn, .btn-secondary {
  color: $white;
  padding: 5px 25px;
  border-radius: 30px;
  text-decoration: none;
  box-shadow: $shadow;
  font-family: $font2;
  font-weight: $bold;
  font-size: $regularplus;
  cursor: pointer;
}

.btn {
  background: $red;

  &:hover {
    background: $lightred;
  }
}

.btn-secondary {
  background: $blue;

  &:hover {
    background: $lightblue;
  }
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: $bolder;
}

.white {
  color: $white;
}

.red, .error {
  color: $red;
}

.gray {
  color: $gray;
}

.error {
  font-size: $smaller;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.hide {
  display: none;
}

@media screen and (max-width: $midsize) {
  h2, h3 {
    font-size: calc(.8 * $large);
  }

  p {
    font-size: calc($regular * .8);
  }

  input, textarea, select {
    font-size: calc(.8 * $small);
    padding: 8px;
  }  

  .btn, .btn-secondary {
    font-size: calc(.8 * $regularplus);
  }

  .error {
    font-size: calc($smaller * .8);
  }
}