@import "./vars";

#homepage {
    #about, #team {
        
        .banner {
            h2 {
                padding: 0 15px;
    
                span {
                    color: $white; 
    
                    &.red {
                        color: $red;
                    }
                }
            }

            @media screen and (max-width: $midsize) {
                text-align: center;
                h2 {
                    text-align: center;
                }
            }
        }
    
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: $desktop;
            margin: auto;

            .img-wrap {
                margin-right: 20px;
                img {
                    max-width: 500px;
                }
            }

            h4 {
                margin-bottom: 20px;
            }
    
            p {
                color: $black;
                font-size: $regularplus;
                margin-bottom: 12px;
                line-height: 1.5;
            }

            @media screen and (max-width: $midsize) {
                display: block;
                max-width: 500px;
                margin: 50px auto 50px;

                .img-wrap {
                    img {
                        width: 100%;
                    }
                }

                p {
                    font-size: calc($regularplus * .8);
                }
            }

            @media screen and (max-width: $phone) {
                .img-wrap {
                    max-width: 250px;
                }
            }

            @media screen and (max-width: 555px) {
                .img-wrap {
                    max-width: 100%;
                    margin: 0 auto 32px;
                    float: none;
                }
            }
        }
    }
}
