@import "./vars";

#pricing {
    background-image: url('../images/bg-split.png');
    .banner {
        .content {
            display: flex;
            align-items: center;
            max-width: $desktop;
            margin: auto;
            padding: 0 15px;

            .fill {
                width: 155.75px;
            }

            @media screen and (max-width: $phone ) {
                flex-direction: row-reverse;
                justify-content: space-between;

                h2 {
                    margin-right: auto;
                    margin-left: 0;
                }
    
                .fill {
                    display: none;
                }
            }
        }
    }

    .content-wrap {
        .content {
            display: -ms-grid;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
            grid-gap: 40px;

            .card {
                box-shadow: $shadow;
                background-color: $white;
                max-width: 450px;
                margin: auto;

                @media screen and (max-width: $midsize) {
                    box-shadow: $shadow;
                    background-color: $white;
                }
            }

            .top {
                padding: 35px;
                border-radius: 15px;

                h3 {
                    padding-bottom: 15px;
                    text-align: center;
                }

                li {
                    margin: 15px 0;
                    display: flex;
                    align-items: center;

                    .check {
                        color: $green;
                        font-size: 32px;
                        margin-right: 10px;

                        @media screen and (max-width: $midsize) {
                            font-size: calc(.8 * 32px);
                            margin-right: 10px;
                        }
                    }
                }
            }

            .bottom {
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;

                .img-wrap {
                    width: 100%;
    
                    img {
                        width: 100%;
                    }
                }

                .text-wrap {
                    padding: 15px 20px;
                    text-align: center;

                    h3, p {
                        padding-bottom: 15px;
                    }

                    .btn-wrap {
                        margin: 15px 0;
                    }

                    .price {
                        font-size: $regularplus;
                        font-family: $font2;
                        font-weight: $bold;
                    }
                }
            }

            @media screen and (max-width: 500px ) {
                display: -ms-grid;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
                grid-gap: 20px;
            }
        }
    }
}