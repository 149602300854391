@import "./vars";

#contact-page {
    .header {
        width: 100%;
        padding: 50px 0;
        background-image: url('../images/contact-header.png');
        background-size: 100%;
        background-repeat: no-repeat;
        text-align: center;

        h1 {
            color: $white;
            font-family: $font3;
            font-size: $extreme;
            letter-spacing: 7px;
            font-weight: $light;
        }

        @media screen and (max-width: $phone) {
            background-image: url('../images/contact-header1.png');

            h1 {
                font-size: calc($extreme * .6);
            }
        }

        @media screen and (max-width: 500px) {
            padding: 30px 0;
        }
    }

    .form-wrap {
        max-width: $desktop;
        margin: auto;
        padding: 60px 15px;

        form {
            width: 100%;

            .flex {
                display: grid;
                grid-template-columns: 1fr 1fr;

                @media screen and (max-width: $phone) {
                    display: block;
                }
            }

            .fieldset {
                padding: 15px;

                .wrap {
                    min-height: 80px;

                    label {
                        border-bottom: 1px solid $black2;
                        margin: 20px 0;
                        color: $gray;
                        justify-content: space-between;
                        align-items: center;
                        height: 48.18px;
                    }

                    input {
                        color: $black2;
                        font-weight: $bold;

                        &::placeholder {
                            color: $gray;
                            font-weight: $medium;
                        }
                    }

                    select {
                        width: 70%;
                        padding: 10px;
                        font-weight: $bold;
                    }

                    textarea {
                        border: solid 1px $black2;
                        border-radius: 7px;
                    }

                    .custom {
                        border: none;
                        height: auto;
                    }
                }
            }

            .btn-wrap {
                padding: 0 15px;
            }

            .btn-secondary {
                padding: 5px 25px;
                font-size: $regular;

                &[disabled] {
                    opacity: 0.8;
                    cursor: default;
                    :hover {
                        background: #155E87;
                        
                    }
                }
            }
        }
    }

    .message {
        margin: 32px 20px;
    }
}