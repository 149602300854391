@import "./vars";

#homepage {
    header {
        background: $black2;
        background-image: url('../images/hero.png');
        background-size: cover;
        height: 90vh;
        scroll-snap-align: center;;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;

        h1 {
            margin-bottom: 40px;
            color: $white;

            span {
                display: block;
                font-family: $font3;
                font-size: $extreme;
                letter-spacing: 7px;
                line-height: 1.6;
                font-weight: $light;
            }
        }

        .btn {
            font-size: $regularplus;
            padding: 8px 28px;
        }
    }

    section {
        .banner {
            background: $black;
            width: 100%;
            padding: 30px 0;
        }

        h2 {
            font-family: $font2;
            font-size: $largest;
            font-weight: $bolder;
            max-width: $desktop;
            margin: auto;

            span {
                font-family: $font2;
            }
        }

        .content-wrap {
            padding: 60px 20px;

            .content {
                max-width: $midsize;
                margin: auto;
            }
        }
    }
}


@media (max-width: $midsize) {
    #homepage {
        header {
            h1 {
                span {
                    font-size: calc(.8 * $extreme);
                    line-height: 1.4;
                }
            }
        }
    
        section {
            h2 {
                font-size: calc(.8 * $largest);
            }

            .content-wrap {    
                .content {
                    max-width: calc($midsize * .8);
                }
            }
        }
    }
}

@media (max-width: $phone) {
    #homepage {
        header {
            
            h1 {
                span {
                    font-size: calc(.6 * $extreme);
                    line-height: 1.2;
                }
            }

            .btn {
                font-size: calc(.8 * $regularplus);
            }
        }
    
        section {
            h2 {
                font-size: calc(.8 * $largest);
            }

            .content-wrap {    
                .content {
                    max-width: calc($midsize * .8);
                }
            }
        }
    }
}

@media (max-width: 500px) {
    #homepage {
        header {
            height: 80vh;
            h1 {
                span {
                    font-size: calc(.4 * $extreme);
                    line-height: 1.2;
                }
            }

            .btn {
                font-size: calc(.6 * $regularplus);
            }
        }
    
        section {
            h2 {
                font-size: calc(.8 * $largest);
            }

            .content-wrap {    
                .content {
                    max-width: calc($midsize * .8);
                }
            }
        }
    }
}

@media (max-width: 410px) {
    #homepage {
        header {
            height: 75vh;
            padding: 10px;
            h1 {
                span {
                    font-size: calc(.3 * $extreme);
                }
            }
        }

        section {
            h2 {
                font-size: calc(.6 * $largest);
            }
        }
    }
}