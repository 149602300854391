@import "./vars";

#not-found-page {
    height: 80vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
        text-align: center;
        font-size: $largest;
        span {
            display: block;
            font-size: $extreme;
        }
    }

    .btn-wrap {
        margin-top: 50px;
    }
}