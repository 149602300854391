$black2: #282c34;
$black: rgb(9, 9, 9);
$white: #fff;
$red: #CF2434;
$darkred: #881520;
$lightred: #e9293c;
$blue: #155E87;
$darkblue: #0f4564;
$lightblue: #1d7bb0;
$green: #067e26;
$gray: #8e8e8e;

// Font Sizes
$fontLarge: 3rem;

// Font Family
$font1: 'Montserrat', sans-serif;
$font2: 'ArchivoNarrow', 'Montserrat', sans-serif;
$font3: 'BebasNeue', 'Montserrat', sans-serif;
$italic: 'ArchivoNarrow-Italic', 'Montserrat', sans-serif;

// Font Weight
$boldest: 900;
$bolder: 700;
$bold: 600;
$medium: 400;
$light: 300;
$lighter: 200;
$lightest: 100;

// Font Size
$extreme: 88px;
$largest: 40px;
$larger: 36px;
$large: 28px;
$regularplusplus: 24px;
$regularplus: 20px;
$regular: 18px;
$small: 16px;
$smaller: 14px;
$smallest: 12px;

// Media Screens
$desktop: 1400px;
$midsize: 1050px;
$phone: 750px;

// Effects
$shadow: 0px 4px 12px rgba(0, 0, 0, 0.24);