@import "./vars";

#homepage {
    #testimonials {
        text-align: center;
        background: $darkred;
    
        .title-wrap {
            padding: 30px 20px;
    
            h2 {
                text-align: center;
                margin-bottom: 10px;
        
                svg {
                    position: relative;
                    top: 5px;
                }
            }
    
            h3 {
                font-size: $regularplus;
            }
        }
    
        .content-wrap {
            padding-top: 20px;
            padding-bottom: 48px;

            .content {
                display: -ms-grid;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                grid-gap: 20px;
    
                .card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
    
                    .name-wrap {
                        z-index: 2;
                        background-color: $green;
                        padding: 15px 30px;
                        border-radius: 15px;
                        margin-bottom: -32px;
                        width: 80%;
                        max-width: 240px;

                        h3 { 
                            color: $white; 
                            font-size: $large;
                        }
                    }

                    .text-wrap {
                        max-width: 320px;
                        width: 100%;
                        background-color: $white;
                        box-shadow: $shadow;
                        padding: 100px 20px 30px;
                        border-radius: 15px;
    
                        h3 {
                            padding-bottom: 15px;
                        }

                        p {
                            padding: 10px 15px;
                            font-size: $small;
                        }

                        svg {
                            font-size: $small;
                        }

                        @media screen and (max-width: $phone) {
                            max-width: 100%;
                        }
                    }
                }
                
            }
        }

        .content-bar {
            background: $blue;
            max-width: $midsize;
            margin: auto;
            margin-bottom: -60px;
            padding: 30px 60px;
            border-radius: 80px;
            z-index: 2;
            position: relative;
            top: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            box-shadow: $shadow;

            .item {
                display: flex;
                align-items: center;
            }

            h3 {
                text-align: left;
                color: $white;
                margin-right: 80px;
            }

            .text {
                justify-content: center;

                p {
                    color: $white;
                }
            }

            .btn-wrap {
                justify-content: center;
                margin-left: 80px;
            }

            @media (max-width: 770px) {
                max-width: 508px;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 15px;
                padding: 30px;

                h3, .btn-wrap {
                    text-align: center;
                    margin: 20px 0;
                }
            }
        }
    }
}