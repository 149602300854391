@import "./vars";

footer {
    background: $black2;
    padding-bottom: 15px;
    text-align: center;

    small {
        color: $white;
        font-size: $smallest;

        .link {
            color: #1BA49C;
            cursor: pointer;
            transition: none;
            text-decoration: none;

            &:hover {
                font-weight: 900;
                text-decoration: underline
            }
        }
    }

    .content-wrap {
        padding: 100px 30px 48px;
        max-width: $desktop;
        margin: auto;

        .content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .newsletter-wrap {
                text-align: left;

                h3, h4 {
                    color: $white;
                    padding-bottom: 10px;
                }

                label {
                    border-bottom: 1px solid $white;
                }

                input {
                    color: $white;

                    &::placeholder {
                        color: $white;
                    }
                }

                .btn {
                    font-size: $small;
                    padding: 4px 20px;

                    margin: 15px 0;
                }
            }

            .menu-wrap {
                text-align: right;

                p {
                    color: $white;
                    font-family: $font2;
                    font-weight: $bold;
                    text-decoration: underline;
                    padding: 10px 0;
                }

                .menu {
                    li {
                        padding: 10px 0;
                    }
                }

                .link {
                    color: $white;
                    font-family: $font2;
                    cursor: pointer;
                    transition: none;
                    text-decoration: none;

                    &:hover {
                        font-weight: $bold;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $midsize) {
    footer {
        small {
            font-size: calc($smallest * .8);
        }

        .content-wrap {
            max-width: calc(.8 * $desktop);

            .content {
                .newsletter-wrap {
                    .btn {
                        font-size: calc(.8 * $small);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $phone) {
    footer {
        .content-wrap {
            .content {
                flex-direction: column;

                .newsletter-wrap {
                    max-width: 360px;
                    margin-right: auto;
                    padding-right: 20px;
                }

                .menu-wrap {
                    width: 100%;
                    margin-left: auto;
                }
            }
        }
    }
}